<template>
    <form enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitCollection">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Request collection</h1>
            </div>
        </div>
        <div v-if="rules && rules.length && rules.filter(rule => rule.is_available).length">
            <div class="row">
                <div class="col-sm-8 col-md-6">
                    <div class="mb-3">
                        <DropdownInput
                            label="Date"
                            v-model="date"
                            :v="v$.date"
                            :options="dateRules"
                        />
                    </div>
                    <div v-if="date.length" class="mb-3">
                        <DropdownInput
                            label="Time"
                            v-model="time_range_key"
                            :v="v$.time_range_key"
                            :options="timeRules"
                        />
                    </div>
                    <div v-if="date.length" class="alert alert-info">Please note, once a collection has been requested, it can only be cancelled by contacting our customer service team.</div>
                </div>
            </div>
            <div>
                <button :disabled="isSubmitting" class="btn btn-primary me-1" type="submit">Submit</button>    
                <router-link class="btn btn-light" to="/collections">Cancel</router-link>
            </div>
        </div>
        <div v-if="rules && (!rules.length || !rules.filter(rule => rule.is_available).length)">
            <div class="alert alert-info">There is already a collection in place for all available days.</div>
            <div><router-link class="btn btn-light" to="/collections">Back</router-link></div>
        </div>
    </form>
</template>

<script>
    import api from "@/services/api";
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import DropdownInput from '@/components/DropdownInput.vue';

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                rules: null,
                date: [],
                time_range_key: null,
                isSubmitting: false,
            }
        },
        validations () {
            return {
                date: { required },
                time_range_key: { required },
            }
        },
        mounted () {
            this.fetchRules();
        },
        computed: {
            dateRules () {
                return this.rules.map((rule, index) => { return {
                    id: index, 
                    label: this.$filters.formatDate(rule.date) + (rule.is_available ? '' : ' (Already booked)'),
                    enabled: rule.is_available,
                }});
            },
            timeRules () {
                if(!this.rules.length || !this.date || !this.rules[this.date])
                {
                    return null;
                }

                var timeRanges = this.rules[this.date].time_range;

                return Object.entries(timeRanges).map((timeRange) => { return {
                    id: timeRange[0], 
                    label: timeRange[1],
                }});
            },
        },
        components: {            
            DropdownInput,
        },
        watch: {
            date() {
                this.time_range_key = null;
            },
        },
        methods: {
            submitCollection: async function () {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.requestCollection();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            requestCollection: function () {
                if (this.isSubmitting) return;
                this.isSubmitting = true;

                api
                .post('/api/v1/collections', {
                    date: this.rules[this.date].date,
                    time_range_key: this.time_range_key,
                    practice_id: this.$store.state.currentPracticeId,
                    user_id: this.$store.state.user.id,
                })
                .then(() => {
                    this.$router.push({'name': 'Collections'});
                })
                .catch(() => {
                    
                });
            },
            fetchRules: function () {
                api
                .get('/api/v1/collections/rules', {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                    }
                })
                .then((response) => {
                    this.rules = response.data;
                })
                .catch(() => {
                    console.log('Catch collection rules error');
                });
            },
        },
    }

</script>
